import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const RocketIcon: FC<IconSVGProps> = ({ width = 38, height = 38 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="rocket, startup, launch">
      <path
        id="vector"
        d="M11.0581 20.9324H8.0807C6.90761 20.9324 6.16222 19.6767 6.72395 18.6469L9.67437 13.2378C9.94518 12.7413 10.4656 12.4324 11.0311 12.4324H17.8088M11.0581 20.9324L17.0682 26.9425M11.0581 20.9324L17.8088 12.4324M17.0682 26.9425V29.9199C17.0682 31.093 18.3239 31.8384 19.3537 31.2766L24.7628 28.3262C25.2593 28.0554 25.5682 27.535 25.5682 26.9695V20.1918M17.0682 26.9425L25.5682 20.1918M25.5682 20.1918C29.9392 16.1851 32.7931 11.8443 33.2353 6.24987C33.3026 5.39899 32.6016 4.69797 31.7507 4.76523C26.1563 5.20748 21.8155 8.06138 17.8088 12.4324M7.88136 33.296H6.25004C5.39651 33.296 4.70459 32.6041 4.70459 31.7506V30.1193C4.70459 28.3648 6.12688 26.9425 7.88136 26.9425C9.63584 26.9425 11.0581 28.3648 11.0581 30.1193C11.0581 31.8737 9.63584 33.296 7.88136 33.296Z"
        stroke="currentColor"
        strokeWidth="2.31818"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
