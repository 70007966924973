import { FC } from 'react';
import styled from 'styled-components';
import { AvatarIcon } from '../Icon/Icons/Avatar.icon';
import { RocketIcon } from '../Icon/Icons/Rocket.icon';
import { colors } from '../../styles/colors';

type Props = {
  className?: string;
  size?: 'small' | 'regular' | 'large';
  url?: string;
  isProject?: boolean;
};

export const ProjectLogo: FC<Props> = (props) => {
  const { className, size = 'regular', url, isProject } = props;

  const logoSizes = {
    small: 50,
    regular: 74,
    large: 100,
  };

  return (
    <StyledLogoWrapper
      className={className}
      size={logoSizes[size]}
      isProject={isProject}
      url={url}
    >
      {url ? (
        <img src={url} alt="" />
      ) : isProject ? (
        <RocketIcon width={logoSizes.small} height={logoSizes.small} />
      ) : (
        <AvatarIcon width={logoSizes.regular} height={logoSizes.regular} />
      )}
    </StyledLogoWrapper>
  );
};

const StyledLogoWrapper = styled.div<{
  size: number;
  isProject?: boolean;
  url?: string;
}>`
  //width: 100%;
  //height: 100%;
  border: 1px solid #edf1f7;
  border-radius: 4px;
  overflow: hidden;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.isProject && !props.url ? colors.lightBlue : colors.white};

  img {
    width: 100%;
    margin: auto;
  }
`;
